body,#root {
  /* background-color: #1b1b1b !important; */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* font-family: monospace; */
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  font-family: "Milliard-Med";

}
.navbar .navbar-menu {
   background: linear-gradient(#181818, #1b1b1b) !important;
   /* background-color: #141414 !important; */
}
@font-face {
  font-family: "Milliard-Med";
  src: url("fonts/Milliard-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Milliard-Regular";
  src: url("fonts/Milliard-Light.otf") format("opentype");
}

@font-face {
  font-family: "Milliard-SemiBold";
  src: url("fonts/Milliard-SemiBold.otf") format("opentype");
}
.footer {
  text-align: center;
  background-color: #141414 !important;
  color: grey;
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 11px;
  height: 20px; 
}

.footer hr {
  border-top-color:#bbb;
  opacity:0.5
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 50000px 0;
  }
}
.App {
  /* max-width: 450px; */
  width: 100%;
  background: #1b1b1b;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 100%;
  min-height: calc(100vh - 20px); /*height of footer*/
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(#181818, rgba(0,0,0,0));

  /*background image*/
  background-image: url("Images/bg-outline-dark.jpg");
  background-repeat: repeat;
  background-position: 0px 0px;
  animation: animatedBackground 6000s linear infinite;
  background-size: 350px 350px;
  
  /* background-size: 25px 25px; */
}

/*moved to hover.css*/
/* .BtnChoice {
  text-decoration: none !important;
  display: flex;
  max-width:280px;
  min-width:280px;
   text-align: center; 
  margin: 0 auto;
  margin-top:10px;
} */

.BtnChoiceNoMargin {
  text-decoration: none !important;
  display: flex;
  max-width:320px;
  min-width:320px;
  text-align: center;
  /* margin: 0 auto; */
}

.BtnChoicePick {
  text-decoration: none !important;
  display: flex;
  text-align: center;  
  margin: 0 auto;
  margin-top:10px;
  justify-content: center;
  align-items: center;
  text-align: -webkit-center;
  vertical-align: middle;
}

.BtnShare {
  text-decoration: none !important;
  display: flex;
  text-align: center;  
  margin: 0 auto;
  /* margin-left: 10px; */
  justify-content: center;
  align-items: center;
  text-align: -webkit-center;
  vertical-align: middle;
}
.navbar {
  color: #ffffff;
  box-shadow: none !important;
}

h2 {
  text-align: center;
  margin: 0 auto;
  display: block;
  font-size: 32px !important;
  font-family: Milliard-SemiBold;
  /* font-weight: bold; */
}

.btn-customSize {
  font-size: 18px !important;
  padding: 0.5rem !important;
}



.btn-lowertext {
  display: block;
  margin-bottom: 1px;
  font-size: 11px;  
  font-family: Milliard-Regular;
}
/* BUTTON HOVER ANIMATIONS*/

@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}
@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}
.hvr-bob {
  display: flex;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  text-decoration: none !important;
  max-width:280px;
  min-width:280px;
  /* text-align: center; */
  justify-content: center;
  margin: 0 auto;
  margin-top:10px;
}

.hvr-bob-bg {
  display: flex;
  vertical-align: middle;
  text-decoration: none !important;
  max-width:280px;
  min-width:280px;
  /* text-align: center; */
  justify-content: center;
  margin: 0 auto;
  margin-top:10px;
  background-color: #1b1b1b;
}

.hvr-bob-sm {
  display: flex;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0) ;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  text-decoration: none !important;
  /* max-width:280px;
  min-width:280px; */
  /* text-align: center; */
  margin: 0 auto;
  margin-top:10px;
}
.hvr-bob:hover, .hvr-bob:focus, .hvr-bob:active {
  -webkit-animation-name: hvr-bob-float;
  animation-name: hvr-bob-float;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}
.hvr-bob-sm:hover, .hvr-bob-sm:focus, .hvr-bob-sm:active {
  -webkit-animation-name: hvr-bob-float;
  animation-name: hvr-bob-float;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}
/*---------------------------------*/

.icon-undo {
  margin-bottom: 3px;
  margin-right: 2px;
  font-size: 18px;
}

.icon-search {
  margin-bottom: 2px;
  margin-right: 2px;
}

.icon-reroll {
  margin-bottom: 3px;
  margin-right: 2px;

}
/* .btn-customSize:hover {
  background-color: purple !important;
} */
.About {
  text-align: center;
  margin: 0 auto;
  padding: 1rem;  
}

.ContactUs {
  text-align: center;
  margin: 0 auto;
  padding: 1rem;    
}

/*--------------------*/
/*fade in animations*/
.isVisible {
  opacity:1;
  transition: width 0.3s, height 0.3s, opacity 0.3s 0.3s;
  align-items: center;
  text-align: center;
  margin: 0 auto;  
}

.isNotVisible {
  opacity:0;
  transition: width 0.3s 0.3s, height 0.3s 0.3s, opacity 0.3s;
  align-items: center;
  text-align: center;
  margin: 0 auto;    
}

/*-------HomeTitle-------*/
.isVisibleHomeTitle {
  opacity:1;
  transition: width 0.6s, height 0.6s, opacity 0.6s 0.6s;
  align-items: center;
  text-align: center;
  margin: 0 auto;  
}

.isNotVisibleHomeTitle {
  opacity:0;
  transition: width 0.6s 0.6s, height 0.6s 0.6s, opacity 0.6s;
  align-items: center;
  text-align: center;
  margin: 0 auto;    
}
/*-------HomePara-------*/

.isVisibleHomePara {
  opacity:1;
  transition: width 1.2s, height 1.2s, opacity 1.2s 1.2s;
  align-items: center;
  text-align: center;
  margin: 0 auto;  
}

.isNotVisibleHomePara {
  opacity:0;
  transition: width 1.2s 1.2s, height 1.2s 1.2s, opacity 1.2s;
  align-items: center;
  text-align: center;
  margin: 0 auto;    
}
/*-------HomeButton-------*/

.isVisibleHomeButton {
  opacity:1;
  transition: width 1.8s, height 1.8s, opacity 1.8s 1.8s;
  align-items: center;
  text-align: center;
  margin: 0 auto;  
}

.isNotVisibleHomeButton {
  opacity:0;
  transition: width 1.8s 1.8s, height 1.8s 1.8s, opacity 1.8s;
  align-items: center;
  text-align: center;
  margin: 0 auto;    
}

.Navbar-Logo {
  max-width:100% !important;
}

.Navbar-Links {
  padding-right: 10px;
}

.spinner {
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  /* margin: 10rem; */
  margin-top: 80px;
  margin-bottom: 80px;
  /* margin: 100px; */
  /* margin: calc(450px- 1rem); */

}
.Pick-border {
  background: #1b1b1b !important;
  border-style:solid;
  border-color: #141414;
  padding-bottom: 1rem;
}

.Pick-border-spinner {
  background: #1b1b1b !important;
  border-style:solid;
  border-color: #141414;

  /*width is 456px as Pick is that, then set max of 100% viewport width - peace out*/
  max-width: 100vw;
  width: 456px;

  /* min-width: 100vmin; */

}

.Pick {
  /* align-items: center;
  padding-top:20px;
  padding-bottom:70px;
  text-align: center; */
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* min-width: 450px; */
  /* max-width: 80vh; */
  max-width: 450px;

}


.Pick-nomovie {
  justify-content: center;
  /* min-width: 450px; */
  /* max-width: 80vh; */
  max-width: 100vw;
  width: 456px;
  padding: 1rem; 
  align-items: center;
  text-align: center;
}

.Pick-nomovie-text {
  padding-bottom: -10px;
}
.Pick-movietitle {
  font-size: 18px;
}

.Pick-left-half {
  flex: 1;
  /* padding-left: 1rem; */
  padding-right: 1rem;
  /* padding-top: 1rem; */
}

/* .Pick-right-half {
  /* flex: 1; 
  /* padding-right: 1rem; 
  /* padding-top: 1rem; 
} */

.Pick-image {
  margin: 0 auto;
}
.Pick-overview {
  font-size: 14px;
  font-family: Milliard-Regular;

}

.Pick-genres {
  font-size:12px;
  font-style: italic;
  margin-top: -4px;

}

.Pick-ratings {
  font-size: 12px;
  flex-direction: row;
}

.Pick-streaming {
  font-size: 12px;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 0px;
}

.Pick-streaming.img {
  margin-top: 50px;
}
.Pick-ratings.text {
  font-size: 12px;
}

.Pick-buttons {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* .Pick-searchicon {
fghgfh
} */

.Home {
  max-width: 580px;
}
.Home-Title {
  font-size: 32px;
  padding: 1rem;
}

.Home-Para {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  font-family: Milliard-Regular;
 
}

.About {
  max-width: 580px;
}
.About-Title {
  font-size: 32px;
  padding: 1rem;
}

.About-Para {
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Milliard-Regular;
  font-size: 14px;
}
.Specific {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.Specific-left {
  padding-right: 5px;
  /* padding-right: 10px; */
  flex: 1;

  /* padding-top: 1rem; */
}


.Specific-right {
  padding-left: 5px;
  /* padding-left: 5px; */
  flex: 1;

  /* padding-top: 1rem; */
}